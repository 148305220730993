import request from '@/common/axios';

// 查询柜子版本列表
export function fetchVersionList(data) {
  return request({
    url: '/platform',
    method: 'POST',
    data: {
      "iReqCode": 1905,
      oReqData: {
        ...data
      }
    }
  });
}

// 添加版本
export function addVersion(data){
    return request({
        url: '/platform',
        method: 'POST',
        data: {
          "iReqCode": 1900,
          oReqData: {
            ...data
          }
        }
      });
}

// 安装版本
export function installVersion(data){
    return request({
        url: '/platform',
        method: 'POST',
        data: {
          "iReqCode": 1908,
          oReqData: {
            ...data
          }
        }
      });
}