<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>
    <div>
      <el-button class="but" type="primary" @click="showAdd"
        >添加版本</el-button
      >
    </div>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="软件名称" prop="apkName"></el-table-column>
      <el-table-column label="版本号" prop="versionNum"></el-table-column>
      <el-table-column label="软件类型" prop="apkGroupId">
        <template #default="scope">
          <eb-badge
            :list="this.const.SOFTWARE_TYPE"
            :target="scope.row.apkGroupId"
          ></eb-badge>
        </template>
      </el-table-column>
      <el-table-column label="描述" prop="description"> </el-table-column>

      <el-table-column label="操作">
        <template #default="scope">
          <span v-if="scope.row.apkGroupId !== this.const.SOFTWARE_TYPE_CODE.APP" class="option option-primary" @click="updataSoftware(scope.row)">更新软件</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      layout=" total,prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>

    <EditModal
      :modalData="modalData"
      :modalType="modalType"
      :ref="MODAL_KEY.EDIT_MODAL"
      @submit="submitStation"
      :agentList="agentList"
    ></EditModal>
  </div>
</template>
 
<script>
import { fetchVersionList, installVersion, addVersion } from "@/api/cabinet";
import EditModal from "./component/EditModal";
export default {
  components: { EditModal },

  data() {
    return {
      dataSource: [],
      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        apkName: "",
        apkGroupId: -1,
        apkUrl: "",
        isOn: -1,
        isUpdate: -1,
        description: "",
        versionNum: "",
      },

      modalData: {},
      modalType: "add",
      MODAL_KEY: {
        EDIT_MODAL: "EDIT_MODAL",
      },
      total: 0,
    };
  },
  methods: {
    showAdd() {
      this.modalData = {};
      this.modalType = "add";
      this.$refs[this.MODAL_KEY.EDIT_MODAL].showModal();
    },

    // doDel(row) {
    //   this.$confirm("是否要删除站点", "提示", {
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     type: "warning",
    //   }).then((res) => {
    //     delStation({ id: row.id }).then((res) => {
    //       this.$message.success("删除成功");
    //       this.getList();
    //     });
    //   });
    // },

    updataSoftware(e) {
      installVersion({
        apkId: e.id,
      }).then((res) => {
        console.log(res);
      });
    },

    submitStation(data) {
      let request = addVersion;
      let msg = "添加版本成功";
      if (this.validatenull(data.id)) {
        request = addVersion;
        msg = "添加版本成功";
      }

      request(data).then((res) => {
        this.$message.success(msg);
        this.$refs[this.MODAL_KEY.EDIT_MODAL].closeModal();
        this.getList();
      });
    },

    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchVersionList({ query: this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records || [];
          this.total = res.data.data.total;
        }
      );
    },
  },

  mounted() {
    this.getList();
  },
};
</script>
