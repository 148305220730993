<template>
  <el-dialog
    :title="modalType === 'edit' ? '修改站点信息' : '添加站点'"
    :close-on-click-modal="isClickModalClose"
    v-model="isShow"
    :width="defaultWidth"
    @close="closeModal"
  >
    <el-form
      :ref="formName"
      :model="modalData"
      :rules="ruleValidate"
      label-width="110px"
    >
      <el-form-item label="版本名称：" prop="apkName">
        <el-input
          v-model="modalData.apkName"
          placeholder="请输入版本名称"
          :maxLength="50"
        ></el-input>
      </el-form-item>

      <el-form-item label="软件版本：" prop="versionNum">
        <el-input
          v-model="modalData.versionNum"
          placeholder="请输入纬度例如"
        ></el-input>
      </el-form-item>

      <el-form-item label="软件类型：" prop="apkGroupId">
        <el-select v-model="modalData.apkGroupId" placeholder="请选择软件类型">
          <el-option v-for="(item, index) in this.const.SOFTWARE_TYPE" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="描述：" prop="description">
        <el-input
          v-model="modalData.description"
          placeholder="请输入详细地址"
        ></el-input>
      </el-form-item>

      <el-form-item label="上传软件：" prop="apkUrl">
        <uploadFile
          action="/v1/FileUpload"
          @success="fileUploadSuccess"
          @remove="fileRemove"
        ></uploadFile>
      </el-form-item>
    </el-form>

    <template #footer>
      <el-button @click="closeModal"> 取消 </el-button>

      <el-button @click="submit" type="primary"> 确定 </el-button>
    </template>
  </el-dialog>
</template>

<script>
import modalMixin from "@/mixins/modalMixin";
import { areas } from "@/common/area.js";
import { downloadUrl } from "@/config/env";
export default {
  mixins: [modalMixin],
  emits: ["submit"],
  props: ["modalData", "modalType", "agentList"],
  data() {
    return {
      downloadUrl: downloadUrl,
      
      formName: "form",
      ruleValidate: {
        apkName: [
          {
            required: true,
            message: "版本名称不能为空",
            trigger: "change",
          },
        ],
        apkGroupId: [
          {
            required: true,
            message: "软件类型不能为空",
            trigger: "change",
          },
        ],
        apkUrl: [
          {
            required: true,
            message: "请上传软件",
            trigger: "change",
          },
        ],

        description: [
          {
            required: true,
            message: "地区不能为空",
            trigger: "change",
          },
        ],
        versionNum: [
          {
            required: true,
            message: "详细地址不能为空",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    //文件上传成功
    fileUploadSuccess(res) {
      this.modalData.apkUrl = res.data.path;
    },

    fileRemove(res) {
      this.modalData.apkUrl = '';
    },

    submit() {
      this.validateForm().then((res) => {
        this.$emit("submit", this.modalData);
      });
    },
  },
};
</script>
